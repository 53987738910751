(function() {
    'use strict';

    const currentUrl = new URL(window.location.href);
    let canonicalUrl = currentUrl.origin + currentUrl.pathname;

    // Caso especial para index.html
    if (currentUrl.pathname.endsWith("index.html")) {
        canonicalUrl = currentUrl.origin + "/";
    }        

    // Crear la etiqueta <link rel="canonical">
    const linkCanonical = document.createElement("link");
    linkCanonical.setAttribute("rel", "canonical");

    // Agregar los parámetros necesarios según la página
    if (currentUrl.pathname.includes("noticia.html") && currentUrl.searchParams.has("id")) {
        linkCanonical.setAttribute("href", canonicalUrl + "?id=" + currentUrl.searchParams.get("id"));
    } else {
        // Páginas estáticas o index.html
        linkCanonical.setAttribute("href", canonicalUrl);
    }

    // Agregar la etiqueta al <head>
    document.head.appendChild(linkCanonical);


    document.addEventListener("DOMContentLoaded", function () {

        const jsonLdGeneral = {
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "BDKREST",
            "description": "Software para restaurantes: Control y registro de ventas, control de comandas, control de mesas, cortes de caja, facturación y más.",
            "image": "https://www.bdkrest.com/bdkrest.jpg",
            "brand": {
                "@type": "Brand",
                "name": "BDKSISTEMAS"
            },
            "offers": {
                "@type": "Offer",
                "price": 5626.00,
                "priceCurrency": "MXN",
                "availability": "http://schema.org/InStock",
                "priceValidUntil": "2099-12-31",
                "seller": {
                    "@type": "Organization",
                    "name": "BDKSISTEMAS",
                    "url": "https://www.bdksistemas.com.mx"
                },
                "hasMerchantReturnPolicy": {
                    "@type": "MerchantReturnPolicy",
                    "url": "https://www.bdksistemas.com.mx/descargas/terminos_y_condiciones_bdk.pdf",
                    "returnPolicyCategory": "MerchantReturnFiniteReturnWindow",
                    "applicableCountry": {
                        "@type": "Country",
                        "name": "Mexico"
                    },
                    "returnFees": "FreeReturn",
                    "merchantReturnDays": 30,
                    "returnMethod": "ReturnByMail"
                },
                "shippingDetails": {
                    "@type": "OfferShippingDetails",
                    "shippingLabel": "Instalación remota",
                    "shippingRate": {
                        "@type": "MonetaryAmount",
                        "value": 0,
                        "currency": "MXN"
                    },
                    "shippingDestination": {
                        "@type": "DefinedRegion",
                        "addressCountry": "Mexico"
                    },
                    "deliveryTime": {
                        "@type": "ShippingDeliveryTime",
                        "handlingTime": {
                            "@type": "QuantitativeValue",
                            "minValue": 0,
                            "maxValue": 0,
                            "unitCode": "DAY"
                        },
                        "transitTime": {
                            "@type": "QuantitativeValue",
                            "minValue": 0,
                            "maxValue": 0,
                            "unitCode": "DAY"
                        }
                    }
                }
            },
            "review": [
                {
                    "@type": "Review",
                    "author": {
                        "@type": "Person",
                        "name": "Toscanos Restaurante"
                    },
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5",
                        "worstRating": "1"
                    },
                    "reviewBody": "Toscanos es un restaurante con un concepto innovador ubicado en Chiquimula, Guatemala. Recomendamos ampliamente los servicios de BDKSISTEMAS.",
                    "datePublished": "2017-03-01"
                },
                {
                    "@type": "Review",
                    "author": {
                        "@type": "Person",
                        "name": "Restaurant Los Arados"
                    },
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5",
                        "worstRating": "1"
                    },
                    "reviewBody": "Gracias a BDKSISTEMAS… Llevamos más de 10 años trabajando con ustedes y hasta la fecha todo bien.",
                    "datePublished": "2022-01-01"
                },
                {
                    "@type": "Review",
                    "author": {
                        "@type": "Person",
                        "name": "Michelager"
                    },
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5",
                        "worstRating": "1"
                    },
                    "reviewBody": "Estamos encantados de haber conocido a BDKSISTEMAS. Al inicio probamos muchas propuestas y la única que nos resultó fue BDKREST.",
                    "datePublished": "2022-05-01"
                }
            ],
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "5",
                "reviewCount": "3"
            }
        };
                

        // Crear y agregar el script JSON-LD al DOM
        const script = document.createElement("script");
        script.type = "application/ld+json";
        script.textContent = JSON.stringify(jsonLdGeneral, null, 2);
        document.head.appendChild(script);

    });

})();
